import { instance } from './axios.config';

export const apgGraphQL = async (
  operationsDoc: string,
  operationName: string,
  variables?: Record<string, any>,
  token?: string
) => {
  return instance.post(
    `${process.env.NEXT_PUBLIC_API_GRAPHQL_URL}`,
    {
      query: operationsDoc,
      variables: variables,
      operationName: operationName,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        ...(token ? { 'Authorization': `Bearer ${token}` } : {})
      }
    }
  )
  .then(result => result.data);
};

export const apgGraphQLforOpenGraph = async (
  operationsDoc: string,
  operationName: string,
  variables?: Record<string, any>,
  token?: string
) => {
  return fetch(`${process.env.NEXT_PUBLIC_API_GRAPHQL_URL}`, {
      method: 'POST',
      body: JSON.stringify({
        query: operationsDoc,
        variables: variables,
        operationName: operationName,
      })
    })
  .then(result => result.json());
};