import { apgGraphQL } from "./apgApi"
import { RequestError } from "./general.endpoints";
import { PaginationResponse } from "@/domain/general.interface";
import { EditProfileSetProps } from "./profile.endpoints";
import { operationProfileHardwares } from "./graph-profile-queries";
import { convertHardwareToOptions, convertTypesToOptions } from "@/lib/utils/generals";
import { SelectOption } from "@apg.gg/core/lib/Select";
import { Hardware, UserHardware } from "@/domain/account/setup.interface";
import { ReactNode } from "react";

export const getError = ({ title, message, response }: RequestError): RequestError => ({ title, message, response })

const get = async <T>({
  userId,
  token,
  limit = 4,
  page = 1
}: any): Promise<PaginationResponse<T>> => {
  const offset = (page - 1) * limit;

  const { data, errors } = await apgGraphQL(
    operationProfileHardwares,
    'GetUserHardwares',
    {
      "where": {
        "userId": { "_eq": userId }
      },
      "limit": limit,
      "offset": offset
    },
    token
  );

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  const totalResults = data.pagination.data.total;
  const resultsPerPage = limit;
  const totalPages = Math.ceil(totalResults / resultsPerPage);

  return {
    items: data.userHardwares,
    totalPages: totalPages,
    total: totalResults,
    currentPage: page,
    hasPreviousPage: page > 1,
    hasNextPage: page < totalPages
  };
}

const getHardwaresSearch = async (query: string): Promise<SelectOption[]> => {
  const { data, errors } = await apgGraphQL(
    operationProfileHardwares,
    'GetHardwaresSearch',
    {
      "where": {
        "_or": [
          {"hardwareCategory": {"name": {"_iregex": `(?i).*${query}.*` }}}, 
          {"hardwareType": {"name": {"_iregex": `(?i).*${query}.*` }}}, 
          {"name": {"_iregex": `(?i).*${query}.*` }}
        ]
      }
    }
  );

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return convertHardwareToOptions(data.hardwares)
}

const getTypes = async (): Promise<SelectOption[]> => {
  const { data, errors } = await apgGraphQL(
    operationProfileHardwares,
    'GetHardwaresTypes',
    {}
  );

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return convertTypesToOptions(data.hardwareTypes)
}

const getCategories = async (): Promise<SelectOption[]> => {
  const { data, errors } = await apgGraphQL(
    operationProfileHardwares,
    'GetHardwaresCategories',
    {}
  );

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return convertTypesToOptions(data.hardwareCategories)
}

const insert = async ({ data: userHardware, userId, token }: EditProfileSetProps<Partial<any>>): Promise<UserHardware> => {
  const { isActive, ...hardware } = userHardware;

  const { data: { insertHardware }, errors: er } = await apgGraphQL(
    operationProfileHardwares,
    'InsertHardware',
    {
      "object": hardware
    },
    token
  )

  if (er) {
    throw getError(er[0] as RequestError)
  }

  if (!insertHardware) {
    throw getError({
      title: 'Error',
      message: 'Hardware not found',
      response: {
        status: 404
      }
    })
  }

  const { id } = insertHardware;

  const { data, errors } = await apgGraphQL(
    operationProfileHardwares,
    'InsertUserHardware',
    {
      "object": {
        hardwareId: id,
        isActive
      }
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.insertUserHardware;
}

const addHardware = async ({ data: userHardware, token }: EditProfileSetProps<Partial<UserHardware>>): Promise<UserHardware> => {
  const { isActive, hardwareId } = userHardware;

  const { data, errors } = await apgGraphQL(
    operationProfileHardwares,
    'InsertUserHardware',
    {
      "object": {
        hardwareId,
        isActive
      }
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.insertUserHardware;
}

const update = async ({ data: userHardware, userId, token }: EditProfileSetProps<Partial<any>>): Promise<UserHardware> => {
  const { isActive, hardware } = userHardware;
  const { id, ...hardwareData } = hardware;

  const { data: updateUserHardware, errors: er } = await apgGraphQL(
    operationProfileHardwares,
    'UpdateUserHardware',
    {
      "_set": {
        "isActive": isActive
      },
      "userId": userId,
      "partnerId": id
    },
    token
  )

  if (er) {
    throw getError(er[0] as RequestError)
  }

  if (!updateUserHardware) {
    throw getError({
      title: 'Error',
      message: 'Hardware not found',
      response: {
        status: 404
      }
    })
  }

  const { data, errors } = await apgGraphQL(
    operationProfileHardwares,
    'UpdateHardware',
    {
      "_set": hardwareData,
      "id": id,
    },
    token
  )

  if (errors) {
    throw getError(er[0] as RequestError)
  }

  return data.updateHardware;
}

const remove = async ({ hardwareId, userId, token }: any): Promise<boolean> => {
  const { errors } = await apgGraphQL(
    operationProfileHardwares,
    'DeleteUserHardware',
    {
      "hardwareId": hardwareId,
      "userId": userId
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return true;
}

export const HardwaresEndpoints = {
  get,
  insert,
  update,
  remove,
  addHardware,
  getTypes,
  getCategories,
  getHardwaresSearch
}
