import axios from 'axios';

const instance = axios.create();

const configureInterceptors = (
  handleFetch: () => void,
  handleInvalidToken: () => void
) => {
  instance.interceptors.request.use(
    (config) => {
      handleFetch();
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      if (response.status === 200) {
        const data = response.data;
        if (data && data.errors) {
          const errors = data.errors;
          for (const error of errors) {
            if (error.extensions && error.extensions.code === 'invalid-jwt') {
              handleInvalidToken();
              return Promise.reject(new Error('Token JWT inválido'));
            }
          }
        }
        return response;
      } else {
        return Promise.reject(response);
      }
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export { instance, configureInterceptors };