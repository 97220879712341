export const operationProfileHardwares = `
  query GetUserHardwares($where: user_hardwares_bool_exp!, $limit: Int!, $offset: Int!) {
    userHardwares: user_hardwares(where: $where, limit: $limit, offset: $offset) {
      userId
      isActive
      hardwareId
      id
      hardware {
        hardwareCategoryId
        hardwareTypeId
        id
        image
        isActive
        link
        name
        hardwareCategory {
          name
        }
        hardwareType {
          name
        }
        brand {
          logo
          link
          name
          slug
        }
      }
    }
    pagination: user_hardwares_aggregate(where: $where) {
      data: aggregate {
        total: count(columns: hardwareId)
      }
    }
  }

  mutation InsertUserHardware($object: user_hardwares_insert_input = {}) {
    insertUserHardware: insert_user_hardwares_one(object: $object) {
      userId
      isActive
      hardwareId
    }
  }

  mutation DeleteUserHardware($userId: Int!, $hardwareId: Int!) {
    deletedUserHardware: delete_user_hardwares_by_pk(userId: $userId, hardwareId: $hardwareId) {
      id
    }
  }  

  query GetHardwares($limit: Int!) {
    hardwares(where: {isActive: {_eq: true}}, limit: $limit) {
      brandId
      hardwareCategory {
        name
        isActive
        id
      }
      brand {
        name
        slug
      }
      hardwareType {
        name
        isActive
        id
      }
      id
      image
      isActive
      link
      name
    }
  }  

  query GetHardwaresSearch($where: hardwares_bool_exp = {}) {
    hardwares(where: $where) {
      brandId
      hardwareCategory {
        name
        isActive
        id
      }
      brand {
        name
        slug
      }
      hardwareType {
        name
        isActive
        id
      }
      id
      image
      isActive
      link
      name
    }
  }

  query GetHardwaresTypes {
    hardwareTypes: hardware_types(where: {isActive: {_eq: true}}) {
      id
      name
    }
  }  

  query GetHardwaresCategories {
    hardwareCategories: hardware_categories(where: {isActive: {_eq: true}}) {
      id
      name
    }
  }
  

  mutation InsertHardware($object: hardwares_insert_input!) {
    insertHardware: insert_hardwares_one(object: $object) {
      name
      link
      isActive
      image
      id
      hardwareTypeId
      hardwareCategoryId
    }
  }
`

export const operationProfileMyLink = `
  query GetUserMyLink($where: my_links_bool_exp = {}) {
    myLinks: my_links(where: $where) {
      backgroundImage
      id
      showCvLink
      socialsAtFooter
      theme
      userId
    }
  }

  mutation InsertOrUpdateMyLink($object: my_links_insert_input = {}) {
    insertOrUpdateMyLink: insert_my_links_one(object: $object, on_conflict: {constraint: my_links_user_id_key, update_columns: [backgroundImage, showCvLink, socialsAtFooter, theme]}) {
      backgroundImage
      id
      showCvLink
      socialsAtFooter
      theme
    }
  }
`

export const operationProfileGalleries = `
  query GetUserAlbums($where: user_albums_bool_exp!, $limit: Int!, $offset: Int!) {
    userAlbums: user_albums(where: $where, limit: $limit, offset: $offset, order_by: {isPinned: desc, createdAt: desc }) {
      userId
      isActive
      isPinned
      isPublished
      albumId
      id
      album {
        id
        description
        descriptionRich
        name
        isPinned
        isActive
        isPublished
        files {
          fileId
          order
          isPinned
          isActive
          id
          file {
            url
            name
            isActive
            width
            height
            id
            caption
          }
          description
        }
      }
      user {
        username
        profileImage
        name
      }
    }
    pagination: user_albums_aggregate(where: $where) {
      data: aggregate {
        total: count(columns: albumId)
      }
    }
  }

  mutation InsertUserAlbum($object: user_albums_insert_input = {}) {
    insertUserAlbum: insert_user_albums_one(object: $object) {
      userId
      isActive
      albumId
    }
  }

  mutation InsertAlbum($object: albums_insert_input = {}) {
    insertAlbum: insert_albums_one(object: $object) {
      id
    }
  }

  mutation DeleteUserAlbum($userId: Int!, $albumId: Int!) {
    deletedAlbum: delete_albums_by_pk(id: $albumId) {
      id
    }
    deletedUserAlbum: delete_user_albums_by_pk(userId: $userId, albumId: $albumId) {
      id
    }
  }  

  mutation InsertAlbumFiles($objects: [album_files_insert_input!] = {}) {
    insertAlbumFiles: insert_album_files(objects: $objects) {
      affected_rows
    }
  }

  mutation UpdateUserAlbum($albumId: Int!, $userId: Int!, $_set: user_albums_set_input!) {
    updateUserAlbum: update_user_albums_by_pk(pk_columns: {albumId: $albumId, userId: $userId}, _set: $_set) {
      albumId
      isActive
      isPinned
      isPublished
      userId
    }
  }

  mutation UpdateAlbum($id: Int!, $_set: albums_set_input!) {
    updateAlbum: update_albums_by_pk(pk_columns: {id: $id}, _set: $_set) {
      id
      description
      descriptionRich
      name
      isPinned
      isActive
      isPublished
      files {
        fileId
        order
        isPinned
        isActive
        id
        file {
          url
          name
          isActive
          width
          height
          id
          caption
        }
        description
      }
    }
  }
`

export const operationProfileFollows = `
  query GetUserFollowers($followId: Int!, $userId: Int!, $limit: Int = 30, $offset: Int) {
    userFollowers: fn_user_follows_aggregate(args: {followid: $followId, userid: $userId}, limit: $limit, offset: $offset) {
      aggregate {
        tota: count(columns: id)
      }
      items: nodes {
        about
        aboutRich
        isFollowed
        isFollower
        name
        profileImage
        userId
        username
        followingId
        id
      }
    }
  }

  query GetUserFollowings($followId: Int!, $userId: Int!, $limit: Int = 30, $offset: Int) {
    userFollowings: fn_user_followings_aggregate(args: {followid: $followId, userid: $userId}, limit: $limit, offset: $offset) {
      aggregate {
        tota: count(columns: id)
      }
      items: nodes {
        about
        aboutRich
        isFollowed
        isFollower
        name
        profileImage
        userId
        username
        followingId
        id
      }
    }
  }  
`

export const operationProfileActions = `
  query GetUserVouched($vouchedUserId: Int!, $limit: Int!, $offset: Int!) {
    userVouches: user_vouchs(where: {vouchedUserId: {_eq: $vouchedUserId}}, limit: $limit, offset: $offset) {
      id
      testimonial
      vouchedUserId
      vouchingUserId
      userVouching {
        bannerImage
        isBetaUser
        isVerified
        name
        profileImage
        username
      }
    }
    pagination: user_vouchs_aggregate(where: {vouchedUserId: {_eq: $vouchedUserId}}) {
      data: aggregate {
        total: count(columns: vouchedUserId)
      }
    }
  }

  query GetUserVouching($vouchingUserId: Int!, $limit: Int!, $offset: Int!) {
    userVouching: user_vouchs(where: {vouchingUserId: {_eq: $vouchingUserId}}, limit: $limit, offset: $offset) {
      id
      testimonial
      vouchedUserId
      vouchingUserId
      userVouched {
        bannerImage
        isBetaUser
        isVerified
        name
        profileImage
        username
      }
    }
    pagination: user_vouchs_aggregate(where: {vouchingUserId: {_eq: $vouchingUserId}}) {
      data: aggregate {
        total: count(columns: vouchingUserId)
      }
    }
  }

  query GetUserFans($idolUserId: Int!, $limit: Int!, $offset: Int!) {
    userFans: user_fans(where: {idolUserId: {_eq: $idolUserId}}, limit: $limit, order_by: {createdAt: desc}, offset: $offset) {
      createdAt
      fanUserId
      id
      idolUserId
      fan {
        id
        isBetaUser
        isVerified
        profileImage
        name
        username
      }
    }
    pagination: user_fans_aggregate(where: {idolUserId: {_eq: $idolUserId}}) {
      data: aggregate {
        total: count(columns: idolUserId)
      }
    }
  }  

  mutation InsertOrUpdateHashtag($object: hashtags_insert_input = {}) {
    insertOrUpdateHashtag: insert_hashtags_one(object: $object, on_conflict: {constraint: hashtags_hashtag_key, update_columns: count}) {
     count
     hashtag
   }
 }
`