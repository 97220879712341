import { HardwareTemplate } from "@/components/EditProfile/SetupModal";
import { ApgUser, UserProfileType } from "@/domain/account.interface";
import { EventType } from "@/domain/account/events.interface";
import { Hardware, HardwareType } from "@/domain/account/setup.interface";
import { Clasification, Country, Game, GameRole, ProfileType } from "@/domain/general.interface";
import { CardProfileProps } from "@apg.gg/core/lib/CardProfile";
import { SelectOption } from "@apg.gg/core/lib/Select";
import renderIcon from "@apg.gg/core/lib/renderIcon";
import { LIMIT_TAGS_ON_CARD_PROFILE } from "./constants";
import Image from 'next/image';

export const convertCountriesToOptions = (countries: Country[]): SelectOption[] => {
  return countries.map((country: Country) => {
    return {
      value: String(country.id),
      label: country.niceName,
      icon: (
        <span className={`fi fi-${country?.iso.toLocaleLowerCase()}`}></span>
      )
    };
  });
}

export const convertProfileTypesToOptions = (profiles: ProfileType[]): SelectOption[] => {
  return profiles.map((profile: ProfileType) => {
    return {
      value: String(profile.id),
      label: profile.name
    };
  });
}

export const convertGamesToOptions = (games: Game[]): SelectOption[] => {
  return games.map((game: Game) => {
    return {
      value: String(game.id),
      label: game.name
    };
  });
}

export const convertGameRolesToOptions = (gameRoles: GameRole[]): SelectOption[] => {
  return gameRoles.map((gameRole: GameRole) => {
    return {
      value: String(gameRole.id),
      label: gameRole.name,
      icon: gameRole.image || ''
    };
  });
}

export const convertClasificationsToOptions = (clasifications: Clasification[]): SelectOption[] => {
  return clasifications.map((clasification: Clasification) => {
    return {
      value: String(clasification.id),
      label: clasification.name,
      icon: clasification.image || ''
    };
  });
}

export const convertEventTypesToOptions = (eventTypes: EventType[]): SelectOption[] => {
  return eventTypes.map((eventType: EventType) => {
    return {
      value: String(eventType.id),
      label: eventType.name
    };
  });
}

export const convertTypesToOptions = (hardwareTypes: HardwareType[]): SelectOption[] => {
  return hardwareTypes.map((hardwareType: HardwareType) => {
    return {
      value: String(hardwareType.id),
      label: hardwareType.name
    };
  });
}

export const convertHardwareToOptions = (hardwares: Hardware[]): SelectOption[] => {
  return hardwares.map((hardware: Hardware) => {
    return {
      value: String(hardware.id),
      label: hardware.name,
      content: <HardwareTemplate hardware={hardware} />,
    };
  });
}

export const mapUsersToCards = (users: ApgUser[], profileTypes: string[]): CardProfileProps[] => {
  return users.map((user: ApgUser, index: number) => {
    const userTags = user.profileTypes?.map((profileType: UserProfileType) => {
      return {
        id: String(profileType.profileType?.id),
        title: profileType.profileType?.name || '',
        type: profileType.profileType?.color,
        icon: <Image src={`/assets/icons/${profileType.profileType?.icon}/small.png`} alt={profileType.profileType?.name || ''} width={16} height={16} />,
        link: `/community/${profileType.profileType?.slug}`,
      }
    }) || [];

    const matchingTags = userTags.filter(tag => profileTypes.includes(tag.title));
    const remainingTags = userTags.filter(tag => !profileTypes.includes(tag.title));
    
    const allTags = [...matchingTags, ...remainingTags];

    // Si hay más de 5 etiquetas, reemplazarlas con "X more"
    const tagsToShow = allTags.length > LIMIT_TAGS_ON_CARD_PROFILE
      ? [...allTags.slice(0, LIMIT_TAGS_ON_CARD_PROFILE), { id: 'more', title: `${allTags.length - LIMIT_TAGS_ON_CARD_PROFILE} more`, type: "white" }]
      : allTags;

    return {
      username: user.username,
      id: user.id,
      name: user.name,
      description: user.about,
      shortDescription: user.about,
      shortRichDescription: user.aboutRich,
      link: `/${user.username}`,
      avatar: user.profileImage || `${process.env.NEXT_PUBLIC_DEFAULT_PROFILE_PIC}`,
      banner: user.bannerImage || '/assets/resources/home-bg-main@2x.png',
      socials: user.socials?.map((social: any) => {
        return {
          id: String(social.socialAccount.id),
          name: social.socialAccount.name,
          url: social.link,
          icon: renderIcon(social.socialAccount.name.toLocaleLowerCase())
        }
      }),
      tags: tagsToShow,
      isVerify: user.isVerified,
      isBeta: user.isBetaUser,
      isFollowing: user.userFollower && user.userFollower.total.count > 0,
      onAction: () => {},
    } as CardProfileProps
  })
}