export const CAN_USE_DOM = typeof window !== 'undefined'

interface ResponseCodes {
  [key: string | number]: string;
}

export const ERROR_CODES = (t: any): ResponseCodes => {
  return {
    3050: t('videos.error-insert-user-video'),
    3051: t('videos.error-update-user-video'),
    3052: t('videos.error-youtube-fetch-channel-id'),
    3053: t('videos.error-youtube-get-videos'),
    'VALIDATION_ERROR_CREATE_USER_GAME': t('games.error-create-game'),
    'VALIDATION_ERROR_UPDATE_USER_GAME': t('games.error-update-game'),
    'VALIDATION_ERROR_PLAYER_TAG_EXISTS': t('games.error-playertag-exists'),
    5000: "Error while signing up",
    5001: "Email already used",
    5002: "Wrong password or email",
    5003: "Email already used",
    5004: "Username already used",
    6001: "Google account already linked to another account",
    7001: "Discord account already linked to another account",
    'Player tag exists': t('games.error-playertag-exists'),
    'FAILED_INSERT_VISIT': t('profile.error-failed-insert-visit'),
    'ALREADY_INSERT_VISIT_TODAY': t('profile.error-already-insert-visit-today'),
    'USER_DOESNT_HAVE_ROLE_ON_DISCORD': t('error-user-doesnt-have-role-on-discord'),
    'INVALID_ROLE_TYPE': t('error-invalid-role-type'),
    'YOU_DO_NOT_HAVE_ENOUGH_COINS_TO_VOUCH_FOR_THIS_USER': t('errors.you-do-not-have-enough-coins-to-vouch-for-this-user'),
    'VOUCH_FAILED': t('errors.vouch-failed'),
    'UPLOAD_FILE_SIZE_LIMIT_EXCEEDED': t('errors.upload-file-size-limit-exceeded', { size: '2MB' }),
    'YOU_DO_NOT_HAVE_ENOUGH_COINS_TO_CRETAE_EVENT': t('errors.you-do-not-have-enough-coins-to-create-event'),
    'EVENT_CREATION_FAILED': t('errors.event-creation-failed'),
    8001: t('errors.cant-start-a-chat-neither-user-follows-the-other'),
    8002: t('errors.you-do-not-have-enough-coins-to-send-message'),
  }
}

export const SUCCESS_CODES = (t: any): ResponseCodes => {
  return {
    'USER_CLAIMED_BADGE_SUCCESSFULLY': t('success-user-claimed-badge-successfully')
  }
}

export const LIMIT_CONTENT = 21;
export const LIMIT_TAGS_ON_CARD_PROFILE = 7;

export const CLASIFICATIONS_OPTIONS = [
  {
    value: '',
    label: 'All',
  },
  {
    value: 'rookie',
    label: 'Rookie',
  },
  {
    value: 'amateur',
    label: 'Amateur',
  },
  {
    value: 'intermedio',
    label: 'Intermedio',
  },
  {
    value: 'profesional',
    label: 'Profesional',
  },
  {
    value: 'casual',
    label: 'Casual',
  },
  {
    value: 'no-opino',
    label: 'No opino',
  }
]